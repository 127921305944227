<template>
  <div class="main-box">
    <el-form class="form-inline" :inline="true" size="small">
      <el-form-item label="">
        <el-input v-model="search.keywords" placeholder="请输入关键词" size="small" clearable></el-input>
      </el-form-item>
      <el-form-item label="">
        <el-date-picker
            size="small"
            v-model="dateRange"
            type="daterange"
            :picker-options="pickerOptions"
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="">
        <el-button type="primary" size="small" @click="handleSearch">搜索</el-button>
      </el-form-item>
    </el-form>
    <el-table
        :data="tableData"
        ref="rfTable"
        style="width: 100%"
        size="mini"
        @selection-change="handleSelectionChange">
      <el-table-column
          prop="id"
          label="ID"
          width="100">
        <template slot-scope="scope">
          <div class="pd-10">{{scope.row.id}}</div>
        </template>
      </el-table-column>
      <el-table-column
          prop="openid"
          label="OPENID"
          align="left"
          min-width="300">
      </el-table-column>
      <el-table-column
          prop="ftime"
          label="注册时间"
          align="center"
          width="240">
      </el-table-column>
      <el-table-column
          fixed="right"
          label="操作"
          align="center"
          width="100">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="lookLog(scope.row)">查看日志</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
        background
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
        layout="total, sizes, prev, pager, next, jumper"
        :page-sizes="[10, 20, 30, 40]"
        :total="search.total">
    </el-pagination>
    <el-dialog
        title="访问日志"
        :visible.sync="dialogVisible"
        width="1000px">
      <visit-log-list :data="checkItem"></visit-log-list>
    </el-dialog>
  </div>
</template>
<script>

import dayjs from "dayjs";
import VisitLogList from "@/components/Statics/VisitLogList";
export default {
  components: {
    VisitLogList
  },
  data() {
    return {
      dialogVisible: false,
      checkItem: {
        openid: '',
        start_time: '',
        end_time: ''
      },
      source_domain: this.config.SOURCE_DOMAIN,
      search: {
        current_page: 1,
        total: 0,
        limit: 10,
        keywords: '',
        status: ''
      },
      tableData: [],
      dateRange: ['',''],
      pickerOptions: {
        shortcuts: [{
          text: '今日',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            // start.setHours(0,0,0,0)
            // end.setHours(23,59,59,999)
            picker.$emit('pick', [start, end]);
          }
        },{
          text: '昨日',
          onClick(picker) {
            var today = new Date();
            today.setDate(today.getDate()-1)
            // start.setHours(0,0,0,0)
            // end.setHours(23,59,59,999)
            picker.$emit('pick', [today, today]);
          }
        }, {
          text: '当周',
          onClick(picker) {
            var start = dayjs().startOf('week').add(1,'day').format('YYYY-MM-DD')
            var end = dayjs().endOf('week').add(1,'day').format('YYYY-MM-DD')
            if(new Date().getDay()==0) { //周日前推6天
              start = dayjs().startOf('week').subtract(6,'day').format('YYYY-MM-DD')
              end = dayjs().endOf('week').subtract(6,'day').format('YYYY-MM-DD')
            }
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '当月',
          onClick(picker) {
            const start = new Date();
            start.setDate(1)
            // start.setHours(0,0,0,0)
            const end = new Date(start.getFullYear(),start.getMonth()+1,0);
            // end.setHours(23,59,59,999)
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '当季度',
          onClick(picker) {
            const today = new Date();
            const year = today.getFullYear();
            const quarter = Math.floor((today.getMonth() + 3) / 3);
            const start = new Date(year, (quarter - 1) * 3, 1);
            const end = new Date(year, quarter * 3, 0);
            // end.setHours(23,59,59,999)
            picker.$emit('pick', [start, end]);
          }
        }]
      }
    };
  },
  created() {
    var start = dayjs().startOf('day').subtract(1,'day').format('YYYY-MM-DD')
    this.dateRange[0] = start
    this.dateRange[1] = start
    this.getList();
  },
  mounted() {
  },
  computed: {
  },
  methods: {
    lookLog(item) {
      this.checkItem.openid = item.openid
      this.checkItem.start_time = this.dateRange[0]
      this.checkItem.end_time = this.dateRange[1]
      this.dialogVisible = true
    },
    getList() {
      var that = this
      that.showLoading()
      var param = {
        page: that.search.current_page,
        page_size: that.search.limit,
        keywords: that.search.keywords
      }
      if(this.dateRange) {
        param.start_time = this.dateRange[0]
        param.end_time = this.dateRange[1]
      }
      this.$api.statics.visitFans(param, function (res) {
        that.hideLoading()
        if(res.errcode == 0) {
          that.tableData = res.data.data
          that.search.current_page = res.data.current_page
          that.search.total = res.data.total
        } else {
          that.fail(res.errmsg)
        }
      })
    },
    showConten(item) {
      this.checkItem = item
      this.dialogVisible = true
    },
    changeStatus(id) {
      this.$api.merchant.noticeMerchantRead({id: id},res => {
        if(res.errcode == 0) {
          this.getList()
        } else {
          this.fail(res.errmsg)
        }
      })
    },
    readAll() {
      this.$api.merchant.noticeMerchantReadAll({},res => {
        if(res.errcode == 0) {
          this.success(res.errmsg)
          this.getList()
        } else {
          this.fail(res.errmsg)
        }
      })
    },
    handleSearch() {
      this.search.current_page = 1
      this.getList()
    },
    handleSelectionChange(rows) {
    },
    handleCurrentChange(val) {
      this.search.current_page = val
      this.getList()
    },
    handleSizeChange(val) {
      this.search.current_page = 1
      this.search.limit = val
      this.getList()
    }
  }
};
</script>
<style scoped>
</style>
