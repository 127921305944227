<template>
  <div class="">
    <el-form class="demo-form-inline" :inline="true" size="mini">
      <el-form-item label="">
        <el-input v-model="search.path" placeholder="请输入页面" size="small" clearable></el-input>
      </el-form-item>
      <el-form-item label="">
        <el-date-picker
            size="small"
            v-model="dateRange"
            type="daterange"
            :picker-options="pickerOptions"
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期">
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" size="small" @click="handleSearch()">搜索</el-button>
      </el-form-item>
    </el-form>
    <el-table
        :data="tableData"
        ref="rfTable"
        style="width: 100%;"
        size="mini">
      <el-table-column
          prop="id"
          label="ID"
          width="100">
      </el-table-column>
      <el-table-column
          prop="openid"
          label="OPENID"
          align="left">
      </el-table-column>
      <el-table-column
          prop="member_id"
          label="会员id"
          align="center"
          width="120">
      </el-table-column>
      <el-table-column
          prop="path"
          label="页面"
          align="left"
          width="300">
        <template slot-scope="scope">
          {{scope.row.path}}{{scope.row.query ? '?' + scope.row.query : ''}}
        </template>
      </el-table-column>
      <el-table-column
          prop="time"
          label="停留时长s"
          align="left"
          width="80">
        <template slot-scope="scope">
          {{parseInt(scope.row.time / 1000)}}
        </template>
      </el-table-column>
      <el-table-column
          prop="create_time"
          label="访问时间"
          align="center"
          width="140">
      </el-table-column>
    </el-table>
    <el-pagination
        background
        @current-change="changePage"
        layout="total, prev, pager, next"
        :page-size="search.limit"
        :total="search.total">
    </el-pagination>
  </div>
</template>

<script>
import dayjs from "dayjs";
export default {
  data() {
    return {
      search: {
        current_page: 1,
        total: 0,
        limit: 10,
        path: '',
        openid: ''
      },
      tableData: [],
      dateRange: ['',''],
      pickerOptions: {
        shortcuts: [{
          text: '今日',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            // start.setHours(0,0,0,0)
            // end.setHours(23,59,59,999)
            picker.$emit('pick', [start, end]);
          }
        },{
          text: '昨日',
          onClick(picker) {
            var today = new Date();
            today.setDate(today.getDate()-1)
            // start.setHours(0,0,0,0)
            // end.setHours(23,59,59,999)
            picker.$emit('pick', [today, today]);
          }
        }, {
          text: '当周',
          onClick(picker) {
            var start = dayjs().startOf('week').add(1,'day').format('YYYY-MM-DD')
            var end = dayjs().endOf('week').add(1,'day').format('YYYY-MM-DD')
            if(new Date().getDay()==0) { //周日前推6天
              start = dayjs().startOf('week').subtract(6,'day').format('YYYY-MM-DD')
              end = dayjs().endOf('week').subtract(6,'day').format('YYYY-MM-DD')
            }
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '当月',
          onClick(picker) {
            const start = new Date();
            start.setDate(1)
            // start.setHours(0,0,0,0)
            const end = new Date(start.getFullYear(),start.getMonth()+1,0);
            // end.setHours(23,59,59,999)
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '当季度',
          onClick(picker) {
            const today = new Date();
            const year = today.getFullYear();
            const quarter = Math.floor((today.getMonth() + 3) / 3);
            const start = new Date(year, (quarter - 1) * 3, 1);
            const end = new Date(year, quarter * 3, 0);
            // end.setHours(23,59,59,999)
            picker.$emit('pick', [start, end]);
          }
        }]
      }
    }
  },
  components: {},
  props: {
    data: Object
  },
  watch: {
    'data': {
      handler (newValue, oldValue) {
        if(newValue.openid) {
          this.search.openid = newValue.openid
        }
        var dateRange = this.dateRange
        if(newValue.start_time) {
          this.dateRange.splice(0,1,newValue.start_time)
        }
        if(newValue.end_time) {
          this.dateRange.splice(1,1,newValue.end_time)
        }
        this.handleSearch()
      },
      deep: true,
      immediate: true
    }
  },
  created() {
    this.getList();
  },
  mounted() {
  },
  methods: {
    getList() {
      var that = this
      that.showLoading()
      var param = {
        page: that.search.current_page,
        page_size: that.search.limit,
        path: that.search.path,
        openid: that.search.openid
      }
      if(this.dateRange) {
        param.start_time = this.dateRange[0]
        param.end_time = this.dateRange[1]
      }
      this.$api.statics.visitLog(param, function (res) {
        that.hideLoading()
        if (res.errcode == 0) {
          that.tableData = res.data.data
          that.search.current_page = res.data.current_page
          that.search.total = res.data.total
        } else {
          that.fail(res.errmsg)
        }
      })
    },
    handleSearch() {
      this.search.current_page = 1
      this.getList()
    },
    changePage(page) {
      this.search.current_page = page
      this.getList()
    }
  }
}
</script>

<style scoped>
</style>
